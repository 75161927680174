.container-center-horizontal {
  background-image: url('https://res.cloudinary.com/dq5lw8oy1/image/upload/v1625708785/CapnCrunch/Backgound_2x_atgboo.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  text-align: center;
  justify-content: center;
  display: flex;
  padding-top: 0px;
  align-items: center;
  min-height: 100vh;
  min-width: 100vw;
}

.container-center-horizontal-age {
  background-color: transparent;
  text-align: center;
  justify-content: center;
  display: flex;
  padding-top: 48px;
  align-items: center;
}

.component-3-1 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 40vh;
  margin: auto;
  width: 100vw;
}
.component-5-1 {
  background-position: 50% 50%;
  background-size: cover;
  height: 114px;
  left: 38px;
  position: absolute;
  top: 39px;
  width: 675px;
}
.component-6-1 {
  background-position: 50% 50%;
  background-size: cover;
  height: 114px;
  left: 38px;
  position: absolute;
  top: 444px;
  width: 675px;
}
.legal-footer {
  display: flex;
  justify-content: center;
  padding: 20px;
  width: 100%;
}

.microsite {
  align-items: center;
  /* background-color: var(--candlelight); */
  display: flex;
  flex-direction: column;
  /* height: 2457px; */
  /* padding: 37.9px 0; */
  /* width: 750px; */
}
.ogbfcc-microsite-image01 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 60vh;
  margin-left: 0;
  width: 100%;
  max-width: 100vw;
}
.ogbfcc-microsite-image02 {
  background-position: 50% 50%;
  background-size: cover;
  height: 414px;
  left: 0;
  position: absolute;
  top: 0;
  width: 750px;
}
.overlap-group-2 {
  height: 603px;
  position: relative;
  width: 750px;
}
.overlap-group1-1 {
  height: 606px;
  position: relative;
  width: 750px;
}
.play-button-gold-2 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 229px;
  margin-top: 142px;
  width: 80vw;
  max-width: 300px;
  cursor: pointer;
}
.rectangle-14-2 {
  height: 201px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 750px;
}
.rectangle-15 {
  height: 201px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 405px;
  width: 750px;
}
