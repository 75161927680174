.age-gate {
  align-items: flex-start;
  display: flex;
  height: 966px;
  overflow: hidden;
  width: 750px;
}
.group-5 {
  height: 25vh;
  object-fit: contain;
  top: 56px;
  max-width: 80vw;
}
.rectangle-14 {
  height: 968px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 750px;
}
.submit-button {
  cursor: pointer;
  height: 171px;
  object-fit: contain;
  width: 600px;
  /* max-width: 1000px; */
  position: absolute;
  top: 75vh;
  justify-content: center;
  left: 50%;
  /* left: calc(26%); */
  margin-left: -300px;
}

.container-center-horizontal {
  text-align: center;
}
.group-12 {
  height: 10vh;
  object-fit: contain;
  position: relative;
  width: auto;
  margin-bottom: 50px;
}
.ageGatePortal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  transition: opacity 0.2s ease;
  z-index: 10000000;
  background-color: transparent;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink {
  color: white;
  font-size: 2em;
  color: white;
  min-width: 50%;
  margin-top: -20%;
}

.MuiInputBase-root.MuiInput-root {
  text-align: center;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-input.MuiInput-input {
  color: white;
  font-size: 10em;
}

.MuiInput-underline {
  border-bottom: none;
}
.MuiInput-underline:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: none;
  pointer-events: none;
}

#date {
  color: white;
}