header.modal-card-head,
footer.modal-card-foot {
  border: 1px solid #f1f2f2;
  background: white;
}

.form-control {
  margin-bottom: 1rem;
}

.form-control input,
.form-control label {
  display: block;
}

.form-control label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.form-control input,
.form-control select {
  font: inherit;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 20rem;
  max-width: 100%;
}

.form-control input:focus {
  outline: none;
  border-color: #240370;
  background-color: #e0d4fd;
}

.control-group {
  display: flex;
  column-gap: 1rem;
  flex-wrap: wrap;
}

.control-group .form-control {
  min-width: 15rem;
  flex: 1;
}

.form-actions {
  text-align: right;
}

.form-actions button {
  margin-left: 1rem;
}

.invalid input {
  border: 1px solid #b40e0e;
  background-color: #fddddd;
}

.invalid input:focus {
  border-color: #ff8800;
  background-color: #fbe8d2;
}

.error-text {
  color: #b40e0e;
} 